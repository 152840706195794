.Testimonials {
    display: flex;
    gap:1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;

}
.left-t>:nth-child(1){
    color: var(--green);
    font-weight: bold;

}
.left-t>:nth-child(2){
    font-size: 3rem;
    font-weight: bold;

}
.left-t>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;

}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    height: 40px;
}
.rigth-t {
    flex: 1;
    position: relative;
}
.rigth-t>img {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top:2rem;


}
.rigth-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 3px solid greenyellow;
    background-color: transparent;
    right: 9rem;
    top:0.9rem;

}
.rigth-t>:nth-child(2) {
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top:4rem;
    background: var(--planCard);
}
.arrows{
    display: flex;
    gap:1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}
.arrows>img{
    width: 1.5rem;

}

@media screen and (max-width: 768px){
    .Testimonials {
        flex-direction: column;
    }
    .left-t > span {
        gap:2rem;
        padding-top: 4rem;
    }
    .left-t > :nth-child(2),
    .left-t > :nth-child(2) {
        font-size: xx-large;
        
    }
    .rigth-t {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .rigth-t > div {
        position: relative;
        display: none;
    }
    .rigth-t > img {
        top:0;
        right: 0;
        position: relative;
        align-self: center;
    }
    .rigth-t > :last-child {
        display: block;
        bottom: 0;
        left: 0;
    }


}